@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

// ======================================================================
// VARS
// ======================================================================

$base-height: 828px;

// 1. Screen resolution
// $tablet-width: 1280px;
// $tablet-height: 800px;
//
// $tv-width: 1080px;
// $tv-height: 1920px;
//
// // 2. Font families
// // -- Gotham
// $font-family-gotham: 'Gotham', sans-serif;
// $gotham-light: 300;
// $gotham-book: 400;
// $gotham-medium: 500;
// $gotham-bold: 700;
// $gotham-black: 900;
//
// // -- Trim
// $font-family-trim: 'Trim', sans-serif;
// $trim-bold: 600;
// $trim-black: 700;
//
// $trim-fat: 800;
// $trim-hefty: 900;
//
// // -- Foundry
// $font-family-foundry: 'Foundry', sans-serif;
// $foundry-normal: 300;
//
// // -- Foundry
// $font-family-itcAvant: 'ITCAvantGarde', sans-serif;
// $itcAvant-demi: 600;
// $itcAvant-book: 400;

// ======================================================================
// WELCOME TV
// ======================================================================

.welcome_tv {
	display: block;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	width: var(--page-width);
	height: var(--page-height);
	white-space: pre-wrap;

	z-index: -1;

	opacity: 1;
	transition: opacity 0.5s ease-out;

	--canvas-width: calc(var(--page-width) * 2.5);
	--canvas-top: 680px;

	&.hidden {
		opacity: 0;
	}
}

.spinner_wrap {
	display: block;
	position: absolute;
	top: var(--canvas-top);
	left: 50%;
	width: var(--canvas-width);
	height: var(--canvas-width);

	transform: translate(-50%, -50%);
}

.logo {
	display: block;
	position: absolute;
	width: 158px;
	height: 150px;
	top: 68px;
	left: 50%;
	margin: 0 -79px;
	background-image: url('../../../assets/images/logo-vype.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.logo_uk {
	background-image: url('../../../assets/images/logo-vuse.svg');
}

.logo_za {
	background-image: url('../../../assets/images/vusego.svg');
	width: 258px;
	margin: 0 -129px;
}

// ======================================================================
// QR
// ======================================================================

.qr {
	position: absolute;
	width: 300px;
	height: 300px;
	top: 50%;
	left: 50%;
	margin: -430px -150px 0;
	padding: 30px;
	background: #fff;
	opacity: 1;
	transform: scale(1);
	transition: all 500ms ease-in-out;
}
.qr_hidden {
	opacity: 0;
	transform: scale(0.1);
}

// ======================================================================
// DEVICE
// ======================================================================

.device {
	position: absolute;
	width: 174px;
	height: 672px;
	top: 320px;
	left: 50%;
	margin: 0 -87px;
	background-image: url('../../../assets/images/vusego.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	opacity: 0;
	transform: scale(0.1);
	transition: all 800ms ease-in-out;
}
.device_uk {
	background-image: url('../../../assets/images/device-epod2.png');
}

.device_za {
	background-image: url('../../../assets/images/vusego.png');
}
.device_visible {
	opacity: 1;
	transform: scale(1);
}
.device_dropped {
	transition: all 500ms ease-in-out;
	transform: translateY(63%) scale(1.2);
}
.device_end {
	opacity: 0;
	transform: scale(1);
	transform: translateY(63%) scale(1.2);
	transition: all 300ms ease-in-out;
}

.device_copy {
	position: absolute;
	bottom: -60px;
	left: -10%;
	width: 120%;
	font-size: 27px;
	line-height: 27px;
	text-align: center;
}

// ======================================================================
// RESULT
// ======================================================================

.result {
	position: absolute;
	top: 325px;
	left: 0;
	width: 100%;
	text-align: center;
	opacity: 0;
	transform: translateY(-30%) scale(0.7);
	transition: all 800ms ease-in-out;
}
.result_visible {
	opacity: 1;
	transform: translateY(0) scale(1);
}
.result_end {
	opacity: 0;
	transform: translateY(0) scale(1);
	transition: all 300ms ease-in-out;
}

.result_title {
	padding: 0 0 26px;
	font-size: 40px;
	line-height: 40px;
	font-weight: $gotham-light;
}
.result_item {
	font-size: 68px;
	line-height: 68px;
	font-weight: $gotham-medium;
	text-transform: uppercase;
}

// ======================================================================
// BASE
// ======================================================================

.base {
	display: block;
	position: absolute;
	width: var(--page-width);
	height: $base-height;
	bottom: 0;
	left: 0;
	background-image: url('../../../assets/images/base.png');
	background-size: cover;
}
.base_inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 270px 100px 0;
	text-align: center;
}
.base_title {
	position: relative;
	min-height: 212px;
	padding: 0 0 22px;
	font-family: $font-family-trim;
	// font-weight: $trim-bold;
	font-weight: $trim-black;
	font-size: 104px;
	line-height: 94px;
	text-transform: uppercase;
}
.base_body {
	min-height: 200px;
	font-size: 36px;
	line-height: 40px;
	font-weight: $gotham-light;
}
.base_hashtag {
	position: absolute;
	bottom: 90px;
	left: 0;
	width: 100%;
	font-size: 29px;
	line-height: 29px;
	font-weight: $gotham-bold;
	text-align: center;
}

// TRANSLATION FIXES
.market_de {
	.base_inner {
		padding: 250px 100px 0;
	}
	.base_title_win {
		margin-top: -30px;
		font-size: 90px;
		line-height: 85px;
	}
	.base_hashtag {
		bottom: 120px;
	}
}

$time: 26s;

.base_frame1,
.base_frame2,
.base_frame3 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.base_frame1 {
	animation: frame1 $time infinite;
}
.base_frame2 {
	animation: frame2 $time infinite;
}
.base_frame3 {
	animation: frame3 $time infinite;
}

@keyframes frame1 {
	0% {
		opacity: 1;
	}
	32% {
		opacity: 1;
	}
	34% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes frame2 {
	0% {
		opacity: 0;
	}
	32% {
		opacity: 0;
	}
	34% {
		opacity: 1;
	}
	66% {
		opacity: 1;
	}
	68% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes frame3 {
	0% {
		opacity: 0;
	}
	66% {
		opacity: 0;
	}
	68% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

// ======================================================================
// BUTTONS
// ======================================================================

.buttons {
	display: block;
	position: fixed;
	bottom: 140px;
	left: 0;
	width: 100%;
	padding: 0 5px;
	margin: 20px auto;
	text-align: center;
	z-index: 10;
}

.btn,
.btn_dev {
	display: inline-block;
	min-width: 94px;
	padding: 10px;
	margin: 2px;
	border-radius: 3px;
	border: none;
	background: #444;
	text-align: center;
	text-decoration: none;
	color: #eee;
	cursor: pointer;

	&:hover {
		background: #555;
	}
}
.btn_dev {
	background: #222;
	color: #666;

	&:hover {
		background: #333;
	}
}
