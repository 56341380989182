@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.persoWrapper {
	color: white;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	width: 100%;
	height: 100%;

	background-size: cover;
	background-position: center;
}

.persoBackground {
	color: white;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	width: 100%;
	height: 100%;

	background-size: cover;
	background-position: center;
	animation: backgroundscale 1.25s;
}

.persoProduct {
	position: absolute;
	top: 2.5%;
	animation: product 3.5s;
}

.persoLogo {
	position: absolute;
	top: 25px;
	right: 25px;
	width: 178px;
	height: auto;
	opacity: 0;
	animation: simpleFade 1s forwards;
}

.persoTextContainer {
	text-align: center;
	position: absolute;
	height: 100%;
	width: 100vw;
	top: 25.5%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	h1 {
		max-width: 100%;
		font-size: 154px;
		line-height: 124px;
		font-family: $font-family-trim;
		font-weight: $trim-black;
        height: fit-content;
        
		& > .wrapSpan {
			white-space: pre;
		}
	}

	h2 {
		font-family: $font-family-gotham;
		font-weight: $gotham-book;
		font-size: 42px;
		width: 60%;
		margin: 0 auto;
		margin-top: 5%;
		opacity: 0;
		animation: fadeUp 1s forwards;
		animation-delay: 3.75s;
		white-space: pre-wrap;
	}

	h3 {
		font-family: $font-family-gotham;
		font-weight: $gotham-medium;
		font-size: 32px;
		margin-bottom: 5%;
		opacity: 0;
		animation: fadeUp 1s forwards;
		animation-delay: 3.25s;
        margin-top: 100px;
		white-space: pre-wrap;
	}
}

@keyframes product {
	0% {
		transform: translateY(150%) scale(1.25);
	}
	50% {
		transform: translateY(20%) scale(1.25);
	}
	75% {
        transform: translateY(20%) scale(1.25);
	}
	100% {
        transform: translateY(0%) scale(1);
	}
}

@keyframes backgroundscale {
	0% {
		-webkit-transform: scale(1.25);
	}
	100% {
		-webkit-transform: scale(1);
	}
}

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes simpleFade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}