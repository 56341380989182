@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

// ======================================================================
// SPINNER
// ======================================================================

.spinner_outer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&.welcome {
		opacity: 0;
	}
}

.spinner_inner {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.spinner_flare {
	position: absolute;
	width: calc(2000/2500 * 100);
	height: calc(2000/2500 * 100);
	top: calc(250/2500 * 100);
	left: calc(250/2500 * 100);
	// background-image: url('../../../assets/images/spinner/flare.png');
	background-size: cover;
}

// .angles {
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background-image: url('../../images/angles.png');
// 	background-size: 74.8% 74.8%;
// 	background-position: center center;
// 	background-repeat: no-repeat;
// 	opacity: 0.6;
// }
//
// .marker {
// 	position: absolute;
// 	top: 50%;
// 	right: 0;
// 	width: 150px;
// 	height: 4px;
// 	margin: -2px;
// 	background: #fff;
// }

// ======================================================================
// BUTTONS
// ======================================================================

.buttons {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 5px;
	margin: 40px auto;
	text-align: center;
	z-index: 10;
}

.btn,
.btn_dev {
	display: inline-block;
	min-width: 94px;
	padding: 10px;
	margin: 2px;
	border-radius: 3px;
	border: none;
	background: #333;
	text-align: center;
	text-decoration: none;
	color: #eee;
	cursor: pointer;

	&:hover {
		background: #444;
	}
}
.btn_dev {
	background: #222;
	color: #666;

	&:hover {
		background: #333;
	}
}
