@import '../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../node_modules/bat-components/dist/styles/includes/functions';

html,
body {
	overflow: hidden;
}

// scale to 50% on smaller screens
@media (max-width: 980px), (max-height: 1720px) {
	html,
	body {
		background: #333;
	}
	main {
		position: relative !important;
		margin: 0 auto !important;
		transform-origin: 50% 0;
		transform: scale(0.5);
		background: #000;
	}
}
