@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.eventWrapper {
	color: white;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	width: 100%;
	height: 100%;

	background-size: cover;
	background-position: center;
}

.textContainer {
	padding: 25px 80px;

	width: 80%;
	height: 700px;
	text-align: left;

	font-family: $font-family-gotham;

	.prefix {
		position: relative;

		font-weight: $gotham-bold;
		letter-spacing: 0;

		font-size: 30px;
		text-transform: uppercase;
	}

	.title {
		position: relative;

		font-family: $font-family-trim;
		font-weight: $trim-black;

		line-height: 0.864;
		letter-spacing: 0;
		font-size: 111px;

		padding-top: 5px;
	}

	.date,
	.price {
		position: relative;

		font-weight: 300;
		letter-spacing: -0.04em;
		font-size: 41px;

		padding: 7px 0;
		text-transform: uppercase;
	}

	.body {
		position: relative;

		margin-top: 40px;
		font-weight: 400;

		line-height: 1.12;
		letter-spacing: -0.01em;
		font-size: 35px;
	}
}
